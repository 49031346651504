export function scrollToEl(trigger) {

    const btns = document.querySelectorAll(trigger);
    if (btns.length) {
        btns.forEach((item) => {
            item.addEventListener('click', (e) => {
                let triggerEl = e.target,
                    targGoal = item.dataset.goal,
                    targ = document.getElementById(targGoal),
                    step = 1,
                    itemCoordinatY = window.scrollY.toFixed(0),
                    final = targ.offsetTop.toFixed(0),
                    btnCloseMenu = document.querySelector('[data-menu-btn]');

                if (getComputedStyle(btnCloseMenu).display != 'none' &&
                    btnCloseMenu.classList.contains('btn-menu--closer')) {
                    btnCloseMenu.click();
                }
                let wpHeader = document.querySelector("#wpadminbar");
                if (wpHeader) {
                    wpHeader = wpHeader.offsetHeight;
                }
                let header = document.querySelector('header.header').offsetHeight;
                let maxheight = Math.max(
                    document.body.scrollHeight,
                    document.body.offsetHeight
                );
                let sumHeaders = wpHeader ? wpHeader + header : header;

                function stepsOfDown() {
                    if (window.scrollY < +final) {
                        requestAnimationFrame(stepsOfDown);
                        window.scrollBy(0, step);
                        step += 1;
                    } else {
                        window.scrollTo(0, +final - +sumHeaders);
                        step = 1;
                    }
                }

                function stepsOfTop() {
                    if (window.scrollY > +final) {
                        requestAnimationFrame(stepsOfTop);
                        window.scrollBy(0, step * -1);
                        step += 1;
                    } else {
                        window.scrollTo(0, +final - +sumHeaders);
                        step = 1;
                    }
                }
                if (+final > +itemCoordinatY) {

                    stepsOfDown();
                } else {
                    stepsOfTop();
                }
            });
        });

    }
}