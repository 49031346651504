export function openInput(parentItem, targetInput, targetLabelText) {

    let inputItem = document.querySelectorAll(targetInput);

    const toggleInput = (input, parent, labelEl) => {
        let label = input.closest(parent).querySelector(labelEl);
        if (label.classList.contains('vl-label__text--open') && input.closest(targetInput).value != '') {} else {
            label.classList.add('vl-label__text--open');
        }

    };
    if (inputItem.length) {
        inputItem.forEach(el => {

            el.addEventListener('click', () => {
                toggleInput(el, parentItem, targetLabelText);
            });

            el.addEventListener('focus', () => {
                toggleInput(el, parentItem, targetLabelText);
            });
            el.addEventListener('blur', () => {
                toggleInput(el, parentItem, targetLabelText);
            });
        });
    }
}