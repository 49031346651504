export function scrollTopMenuHide() {
    const header = document.querySelector('.header');
    let scrollPrev = 0;
    window.addEventListener('scroll', () => {
        let scrolled = window.pageYOffset * 2;

        if (scrolled > document.documentElement.clientHeight && scrolled > scrollPrev) {

            header.classList.add('header--hide');


        } else {
            header.classList.remove('header--hide');
        }
        scrollPrev = scrolled;
    });

}