export const openMenu = () => {

    const toogler = (button, classToggler, menu, closeClass) => {

        const btn = document.querySelector(button),
            list = document.querySelector(menu);
        if (btn) {

            btn.addEventListener('click', () => {
                btn.classList.toggle(classToggler);
                list.classList.toggle(closeClass);
                document.body.classList.toggle('_lock');
            });
        }


    }
    toogler('[data-menu-btn]', 'btn-menu--closer', '[data-menu-mob]', 'header__inner--open-mob');

};