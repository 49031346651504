export function accordion(accor, accorItem, btn, accorParentItem) {
    const accordion = document.querySelector(accor);
    if (accordion) {

        let listitems = accordion.querySelectorAll(accorItem);
        let item = null;
        let paddingItem = getComputedStyle(listitems[0]).getPropertyValue("--padd-top");

        accordion.addEventListener('click', (e) => {

            let targ = e.target;
            if (targ.closest(btn)) {
                item = targ.closest(accorParentItem).querySelector(accorItem);
                paddingItem = getComputedStyle(item).getPropertyValue("--padd-top");
                if (item.classList.contains('vl-accordion__info--open')) {
                    item.classList.remove('vl-accordion__info--open');
                    item.style.setProperty('--height', '');
                    targ.classList.remove('vl-accordion__btn--open');
                    targ.setAttribute('aria-expanded', 'false');
                } else {
                    listitems.forEach((el) => {
                        el.classList.remove('vl-accordion__info--open');
                        el.style.setProperty('--height', '');
                        el.closest('.vl-accordion__item').querySelector('.vl-accordion__btn').classList.remove('vl-accordion__btn--open');
                    });
                    item.classList.add('vl-accordion__info--open');
                    targ.classList.add('vl-accordion__btn--open');
                    targ.setAttribute('aria-expanded', 'true');
                    let height = item.scrollHeight + +paddingItem;
                    item.style.setProperty('--height', height + 'px');
                }
            }
        });
        addEventListener("resize", (event) => {
            let openItem = accordion.querySelector('.vl-accordion__info--open');
            if (openItem) {
                openItem.classList.remove('vl-accordion__info--open');
                openItem.style.setProperty('--height', '');
                setTimeout(() => {
                    openItem.classList.add('vl-accordion__info--open');
                    let height = openItem.scrollHeight;
                    openItem.style.setProperty('--height', height + 'px');
                }, 400);
            }

        });
    }
}