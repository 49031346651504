import './index.html';
import './index.scss';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import * as headerMenu from './modules/mobMenu';
import * as hideHeader from './modules/navMenu';
import * as funAccor from './modules/vl-accordion';
import * as funcForm from './modules/vl-open-input-form';
// import * as funcCount from './modules/anima-counter';
import * as funcScrollToBlocks from './modules/scrollToBlocks';

import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';


document.addEventListener("DOMContentLoaded", () => {
    headerMenu.openMenu();

    hideHeader.scrollTopMenuHide();
    funcScrollToBlocks.scrollToEl('[data-trigger]');
    funAccor.accordion('[data-accordion]', '[data-accor-item]', '[data-accor-btn]', '[data-accor-parent]');

    funcForm.openInput( /*[data-form-body]',*/ '[data-form-parent]', '.vl-label__input', '[data-form-label]');

    const swiper = new Swiper('.feed-slider', {
        pagination: {
            el: ".swiper-pagination",
            type: "bullets"
        },
        loop: true,
        autoplay: {
            delay: 500,
            disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {

            576: {
                slidesPerView: 2,
                spaceBetween: 20,
            },

            1100: {
                slidesPerView: 3,
                spaceBetween: 80,
            }
        }

    });

    AOS.init();

    // funcCount.counterAnimated();
});